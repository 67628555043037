<template>
<!--  <img alt="Vue logo" src="./assets/logo.png">-->
<!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import { resetRem } from '@/components/rem';
export default {
  name: 'App',
  components: {
    // HelloWorld
  },
    created() {
        resetRem();
        // 改变窗口大小时重新设置 rem
        window.onresize = () => {
            resetRem();
        };
        let token = localStorage.getItem('token');
        // localStorage.setItem('cookieShow',false);
        if(token){
            // console.log(token);
            this.$store.commit({
                type: 'setToken',
                token: token
            })
            // this.login(userInfo)
        }
    },
    onLaunch(){

    },
}
</script>

<style>
.el-message{
    font-size: 0.16rem;
}
@font-face {
    font-family: "SourceHanSansCNMedium";
    src: url('assets/SourceHanSansCN-Medium.otf');
    font-weight: 500;
}
@font-face {
    font-family: "HelveticaNeueMedium";
    src: url('assets/HelveticaNeue-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: "HelveticaNeueLight";
    src: url('assets/HelveticaNeue-Light.ttf');
}
@font-face {
    font-family: "SourceNormal";
    src: url('assets/SourceHanSansCN-Normal.ttf');
}
@font-face {
    font-family: "ExpandedBold";
    src: url('assets/PujiSans-ExpandedBold.ttf');
}
.ExpandedBold{
    font-weight: 400;
    font-family: "ExpandedBold";
}
.SourceNormal{
    font-weight: 400;
    font-family: "SourceNormal";
}
.normal{
    font-weight: 300;
    font-family: "HelveticaNeueLight";
}
.en{
    font-weight: 400;
    font-family: "HelveticaNeueMedium";
}
.title_en{
    font-weight: 400;
    font-family: "HelveticaNeueMedium";
}
*{
    margin: 0;
    padding: 0;
    font-family: "SourceHanSansCNMedium";
    font-weight: 500;
}
.three{
    font-weight: 350;
}
.three0{
    font-weight: 300 !important;
}
.four{
    font-weight: 400;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    font-size: 0.16rem;
}
</style>
