// import Vue from 'vue'
// import Router from 'vue-router'
//
//
// Vue.use(Router)
import { createRouter, createWebHistory } from 'vue-router'

const routerHistory = createWebHistory()

console.log(routerHistory)
const routes=[
    //这里和vue2一样
    {
        path: '/',
        name: 'home',
        component: () => import('@/view/home/index')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/view/home/privacy')
    },
    {
        path: '/term',
        name: 'term',
        component: () => import('@/view/home/term')
    },
    //注册
    {
        path: '/register',
        name: 'register',
        redirect: '/register/home',
        component: () => import('@/view/register/index'),
        children:[
            {
                path: 'home',
                name: 'registerHome',
                component: () => import('@/view/register/home')
            },
            {
                path: 'password',
                name: 'registerPassword',
                component: () => import('@/view/register/password')
            },
            {
                path: 'identity',
                name: 'identity',
                component: () => import('@/view/register/identity')
            },
        ]
    },
    //登录
    {
        path: '/logon',
        name: 'logon',
        redirect: '/logon/home',
        component: () => import('@/view/logon/index'),
        children:[
            {
                path: 'home',
                name: 'logonHome',
                component: () => import('@/view/logon/home')
            },

        ]
    },
    //忘记密码
    {
        path: '/forget',
        name: 'forget',
        redirect: '/forget/home',
        component: () => import('@/view/forget/index'),
        children:[
            {
                path: 'home',
                name: 'forgetHome',
                component: () => import('@/view/forget/home')
            },
            {
                path: 'rebuild',
                name: 'rebuild',
                component: () => import('@/view/forget/rebuild')
            },
        ]
    },
    //活动
    {
        path: '/activity',
        name: 'activity',
        redirect: '/activity/home',
        component: () => import('@/view/activity/index'),
        children:[
            {
                path: 'home',
                name: 'activityHome',
                component: () => import('@/view/activity/home')
            },
            {
                path: 'application',
                name: 'activityApplication',
                component: () => import('@/view/activity/application')
            },
            {
                path: 'success',
                name: 'success',
                component: () => import('@/view/activity/success')
            },
        ]
    },
    //资讯
    {
        path: '/information',
        name: 'information',
        redirect: '/information/home',
        component: () => import('@/view/information/index'),
        children:[
            {
                path: 'home',
                name: 'informationHome',
                component: () => import('@/view/information/home')
            },
        ]
    },
    //课程
    {
        path: '/course',
        name: 'course',
        redirect: '/course/home',
        component: () => import('@/view/course/index'),
        children:[
            {
                path: 'home',
                name: 'courseHome',
                component: () => import('@/view/course/home')
            },
        ]
    },
    //教师列表
    {
        path: '/teacher',
        name: 'teachers',
        redirect: '/teacher/home',
        component: () => import('@/view/teacher/index'),
        children:[
            {
                path: 'home',
                name: 'teacherHomes',
                component: () => import('@/view/teacher/home')
            },
            {
                path: 'details',
                name: 'teacherDetails',
                component: () => import('@/view/teacher/details')
            },
        ]
    },
    //赛事
    {
        path: '/game',
        name: 'game',
        redirect: '/game/home',
        component: () => import('@/view/game/index'),
        children:[
            {
                path: 'home',
                name: 'gameHome',
                component: () => import('@/view/game/home')
            },
            {
                path: 'eventDetails',
                name: 'eventDetails',
                component: () => import('@/view/game/eventDetails')
            },
            {
                path: 'rules',
                name: 'rules',
                component: () => import('@/view/game/rules')
            },
            {
                path: 'application',
                name: 'application',
                component: () => import('@/view/game/application')
            },

        ]
    },
    //我的
    {
        path: '/my',
        name: 'my',
        redirect: '/my/home',
        component: () => import('@/view/my/index'),
        children:[
            {
                path: 'home',
                name: 'myHome',
                component: () => import('@/view/my/home')
            },
            {
                path: 'registrationDetails',
                name: 'registrationDetails',
                component: () => import('@/view/my/registrationDetails')
            },
            {
                path: 'playPlay',
                name: 'playPlay',
                component: () => import('@/view/my/playPlay')
            },
            {
                path: 'collect',
                name: 'collect',
                component: () => import('@/view/my/collect')
            },
            {
                path: 'teacher',
                name: 'teacher',
                component: () => import('@/view/my/teacher')
            },
            {
                path: 'review',
                name: 'review',
                component: () => import('@/view/my/review')
            },
            {
                path: 'member',
                name: 'member',
                component: () => import('@/view/my/member')
            },
            {
                path: 'password',
                name: 'password',
                component: () => import('@/view/my/password')
            },
            {
                path: 'letter',
                name: 'letter',
                component: () => import('@/view/my/letter')
            },
        ]
    }


]
const router = createRouter({
    history: routerHistory,
    routes
})

export default router
