// import Vue from 'vue'
import { createApp } from 'vue'
const app = createApp(App)
import Vuex from 'vuex'
import App from "@/App.vue";
app.use(Vuex)
export default new Vuex.Store({

    state: {
        token:'',
        isLogin:false
    },

    mutations: {
        setToken(state,data){
            state.token = data.token
            state.isLogin = true
            localStorage.setItem('token', data.token);
            localStorage.setItem('isLogin',true);
        },
        outToken(state,data){
            state.isLogin = false
            localStorage.setItem('isLogin',false);
        }

    },
    getters: {
        //get方法
        getToken: state => state.token

    },
    actions: {

        // change_sex_by_action (context,payload) {
        //
        //     setTimeout(() => {
        //
        //         context.commit('change_sex',payload)
        //
        //     },3000)
        //
        // }

    }

})