import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
// import 'element-ui/lib/theme-chalk/index.css';
// import ElementUI from 'element-ui';
import ElementPlus from 'element-plus';
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import store from "@/store";
import 'element-plus/theme-chalk/index.css';
const app = createApp(App)
import dragscroll from '@/components/directives.js'; // 引入鼠标拖拽滚动图片指令文件

//input 数字 小数位计算
import InputFloat from '@/components/InputFloat';
app.directive('input-float', InputFloat)

// 注册鼠标拖拽滚动图片指令

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)

});
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//     app.component(key, component)
// }
app.directive('dragscroll', dragscroll);
app.use(router)
app.use(store)
app.use(ElementPlus, { zhCn })
// app.use(Drawer)
app.mount('#app')
// createApp(App,router).mount('#app')
